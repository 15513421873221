<template>
  <b-card>
    <b-row>
      <b-col
        md="2"
        sm="4"
        class="my-1"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">Afficher</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>

      <b-col md="8" />
      <b-col
        md="2"
        sm="4"
        class="my-1 text-right"
      >
        <b-form-group class="mb-0">
          <b-button
            v-b-modal.modal-addUtilisateur
          >
            Nouvel utilisateur
          </b-button>
        </b-form-group>

        <!-- Modal Upload -->
        <b-modal
          id="modal-addUtilisateur"
          ref="modal-addUtilisateur"
          cancel-variant="outline-secondary"
          ok-title="Ajouter"
          cancel-title="Annuler"
          centered
          title="Nouvel utilisateur"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
        >
          <b-form @submit.stop.prevent="handleSubmit">
            <b-alert
              :show="newUser.error === true"
              variant="danger"
            >
              Merci de saisir tout les champs
            </b-alert>
            <b-form-group>
              <label for="nom">Nom :</label>
              <b-form-input
                id="nom"
                v-model="newUser.nom"
                type="text"
              />
            </b-form-group>
            <b-form-group>
              <label for="nom">Prénom :</label>
              <b-form-input
                id="prenom"
                v-model="newUser.prenom"
                type="text"
              />
            </b-form-group>

            <b-form-group>
              <label for="nom">Téléphone :</label>
              <b-form-input
                id="tel"
                v-model="newUser.tel"
                type="text"
              />
            </b-form-group>

            <b-form-group>
              <label for="nom">Email :</label>
              <b-form-input
                id="email"
                v-model="newUser.email"
                type="text"
              />
            </b-form-group>

            <b-form-group>
              <label for="nom">Profil :</label>
              <v-select
                v-model="newUser.profil"
                label="text"
                :options="profils"
              />
            </b-form-group>

          </b-form>
        </b-modal>

      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
        >
          <template #cell(actions)="data">
            <router-link :to="{ name: 'admin-utilisateurs-detail', params: { id: encrypt(data) }}">
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-primary"
                class="btn-icon"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </router-link>
          </template>

          <template #cell(client)="data">
            <div class="d-flex align-items-center">
              <div>
                <div class="font-weight-bolder">
                  {{ data.item.prenom }} {{ data.item.nom }}
                </div>
                <div class="font-small-2 text-muted">
                  {{ data.item.societe }}
                </div>
              </div>
            </div>
          </template>

          <template #cell(projet)="data">
            <span v-if="data.item.projet">
              {{ data.item.projet.nom }}
            </span>
            <span v-else>-</span>

          </template>

          <template #cell(roles)="data">
            <b-badge :variant="getVariant(data.value)">
              {{ getVariant(data.value,'text') }}
            </b-badge>
          </template>

          <template #cell(etat)="data">
            <b-badge
              :variant="getVariantEtat(data.value)"
              @click="updateEtat(data)"
            >
              {{ getVariantEtat(data.value,'text') }}
            </b-badge>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import {
  BCard, BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BButton, BModal, VBModal, BForm, BFormInput, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

import CryptoJS from 'crypto-js'

const config = require('@/../config')

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BAlert,
    ToastificationContent,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [5, 10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: 'actions', label: '' },
        { key: 'client', label: 'Client' },
        { key: 'email', label: 'Email' },
        { key: 'emailSecondaire', label: 'Email Secondaire' },
        { key: 'projet', label: 'Projet' },
        { key: 'roles', label: 'Profil' },
        { key: 'etat', label: 'Statut' },

      ],
      profils: [
        { id: 'ROLE_CLIENT', text: 'Client' },
        { id: 'ROLE_CONSTRUCTEUR', text: 'Constructeur' },
        { id: 'ROLE_ADMIN', text: 'Administrateur' },
      ],
      items: [],
      newUser: {
        nom: '',
        prenom: '',
        email: '',
        tel: '',
        profil: '',
        etat: 'actif',
        error: false,
      },
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length

    this.list()
  },
  methods: {
    list() {
      this.$store.dispatch('utilisateurs/fetchAll', { page: this.currentPage, limit: this.perPage }).then(
        res => {
          this.items = res.utilisateurs
          this.totalRows = res.total
        },
      )
    },
    updateEtat(data) {
      let etat = 'ACTIF'
      if (data.item.etat === 'ACTIF') etat = 'INACTIF'

      this.$store.dispatch('utilisateurs/updateUserData', { id: data.item.id, etat }).then(() => {
        this.items[data.index].etat = etat
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Modification effectuée',
            icon: 'BellIcon',
            text: `Le compte de ${data.item.prenom} ${data.item.nom} est passé en ${etat}`,
            variant: 'success',
          },
        })
      })
    },
    getVariantEtat(data, type) {
      let etat = 'Actif'
      let color = 'success'

      if (data === 'INACTIF') {
        etat = 'Inactif'
        color = 'danger'
      } else if (data === 'A VALIDER') {
        etat = 'A valider'
        color = 'warning'
      }

      if (type && type === 'text') return etat
      return color
    },
    getVariant(data, type) {
      let role = 'Client'
      let color = 'primary'

      if (data.includes('ROLE_ADMIN')) {
        role = 'Administrateur'
        color = 'warning'
      } else if (data.includes('ROLE_CONSTRUCTEUR')) {
        role = 'Constructeur'
        color = 'secondary'
      }
      if (type && type === 'text') return role
      return color
    },
    encrypt(data) {
      return CryptoJS.AES.encrypt(data.item.id.toString(), config.crypto_key).toString()
    },
    resetModal() {
      this.newUser.nom = ''
      this.newUser.prenom = ''
      this.newUser.profil = ''
      this.newUser.etat = 'actif'
      this.newUser.error = false
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      if (this.newUser.nom !== '' && this.newUser.prenom !== '' && this.newUser.email !== '' && this.newUser.profil !== '' && this.newUser.tel !== '') {
        // Trigger submit handler
        this.handleSubmit()
      } else {
        this.newUser.error = true
      }
    },
    handleSubmit() {
      const user = this.newUser
      user.profil = this.newUser.profil.id
      this.$store.dispatch('utilisateurs/newFromAdmin', user)
        .then(r => {
          if (r.status === 200 || r.status === 201) {
            this.list()
            this.resetModal()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$refs['modal-addUtilisateur'].toggle('#toggle-btn')
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
